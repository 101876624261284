<template>
  <div>
    <!-- Enquiry Form -->
    <div class="position-relative bg-img-hero" :style="{ backgroundImage: `url(${require(`@/assets/svg/components/general-bg-3.svg`)})`}">
      <div class="container space-2">
        <div class="w-md-85 w-lg-65 mx-auto text-center">
          <h1 class="mb-3">{{ $t('ContactPage.Title') }}</h1>
          <p class="lead">{{ $t('ContactPage.Description') }}</p>
        </div>
        <div class="w-lg-80 mx-auto mt-5">
          <div class="card z-index-2">
            <div class="card-body">
              <div id="hubspotRegisterForm" />
            </div>
          </div>
        </div>
      </div>
      <figure class="position-absolute right-0 bottom-0 left-0 mb-n1">
        <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
          <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
        </svg>
      </figure>
    </div>

    <!-- Contact Methods -->
    <div class="container">
      <div class="mt-5 space-bottom-2">
        <div class="row">
          <div v-for="item in contact_list" :key="item.title" class="col-md-4 mb-5 mb-md-0">
            <div class="card h-100">
              <div class="card-body">
                <figure class="max-w-6rem w-100 mb-3">
                  <img class="img-fluid" :src="require(`@/assets/svg/icons/${item.icon}.svg`)">
                </figure>
                <h4>{{ $t(item.title) }}</h4>
                <span v-html="$t(item.description)" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Contact',
  data() {
    return {
      contact_list: [
        {
          title: 'ContactPage.Contact1.Title',
          description: 'ContactPage.Contact1.Description',
          icon: 'icon-15'
        },
        {
          title: 'ContactPage.Contact2.Title',
          description: 'ContactPage.Contact2.Description',
          icon: 'icon-3'
        },
        {
          title: 'ContactPage.Contact3.Title',
          description: 'ContactPage.Contact3.Description',
          icon: 'icon-7'
        }
      ]
    }
  },
  mounted() {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)
    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '7091000',
          formId: 'b0759c2b-b8f9-4174-bebe-334b98efcf62',
          errorClass: 'hubspotFormError',
          target: '#hubspotRegisterForm'
        })
      }
    })
  },
  metaInfo() {
    return {
      title: 'OpenAPIHub',
      titleTemplate: 'Contact Us | %s',
      link: [
        { rel: 'canonical', href: 'https://www.openapihub.com/contact' }
      ],
      meta: [
        { name: 'description', content: 'Have a question? Send us a note and let us know how we can help.' },
        { property: 'og:site_name', content: 'OpenAPIHub' },
        { property: 'og:title', content: 'Contact Us | OpenAPIHub' },
        { property: 'og:description', content: 'Have a question? Send us a note and let us know how we can help.' },
        { property: 'og:image', content: 'https://www.openapihub.com/assets/img/thumbnails/openapihub-2.jpg' },
        { property: 'og:url', content: 'https://www.openapihub.com/contact' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
